<script>
import { deleteProduct } from "../../api/products.api";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default {
  components: {
    Dialog,
    Button,
  },
  props: ["show", "productId"],
  emits: ["update:show", "refetch:products"],
  methods: {
    handleDelete(id) {
      deleteProduct(id)
        .then((response) => {
          this.$toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: "Product deleted",
            life: 3000,
          });
          this.$emit("update:show", false);
          this.$emit("refetch:products");
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "an Error has occurred while deleting the expense",
            life: 3000,
          });
        });
      console.log("Delete expense with id: ", id);
    },
    handleClose() {
      this.$emit("update:show", false);
    },
  },
  computed: {
    showDeleteModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
};
</script>
<template>
  <Dialog
    header="Delete Confirmation"
    :visible.sync="showDeleteModal"
    :modal="true"
  >
    <div class="confirmation-content flex items-center">
      <i
        class="pi pi-exclamation-triangle mr-3 text-red-600"
        style="font-size: 2rem"
      />
      <span>Are you sure you want to delete this product?</span>
    </div>
    <template #footer>
      <Button label="Cancel" @click="handleClose" class="p-button-text" />
      <Button
        label="Confirm"
        @click="handleDelete(productId)"
        class="p-button-text p-button-danger"
      />
    </template>
  </Dialog>
</template>
