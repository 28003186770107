var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Dialog', {
    attrs: {
      "header": "Delete Confirmation",
      "visible": _vm.showDeleteModal,
      "modal": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showDeleteModal = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('Button', {
          staticClass: "p-button-text",
          attrs: {
            "label": "Cancel"
          },
          on: {
            "click": _vm.handleClose
          }
        }), _c('Button', {
          staticClass: "p-button-text p-button-danger",
          attrs: {
            "label": "Confirm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleDelete(_vm.productId);
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "confirmation-content flex items-center"
  }, [_c('i', {
    staticClass: "pi pi-exclamation-triangle mr-3 text-red-600",
    staticStyle: {
      "font-size": "2rem"
    }
  }), _c('span', [_vm._v("Are you sure you want to delete this product?")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }