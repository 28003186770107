var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-8"
  }, [_c('div', {
    staticClass: "p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
  }, [_c('DeleteProductModal', {
    attrs: {
      "show": _vm.showDeleteModal,
      "product-id": _vm.productId
    },
    on: {
      "update:show": [function ($event) {
        _vm.showDeleteModal = $event;
      }, _vm.handleToggleModal],
      "refetch:products": _vm.fetchProducts
    }
  }), _vm._m(0), _c('div', [_c('DataTable', {
    ref: "dt",
    staticClass: "p-datatable",
    attrs: {
      "value": _vm.formattedProducts,
      "paginator": true,
      "rows": 10,
      "dataKey": "_id",
      "loading": _vm.loading,
      "rowHover": true,
      "paginatorTemplate": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rowsPerPageOptions": [10, 25, 50],
      "currentPageReportTemplate": "Showing {first} to {last} of {totalRecords} entries",
      "globalFilterFields": ['name', 'code', 'ref'],
      "responsiveLayout": "scroll",
      "filters": _vm.filters
    },
    on: {
      "update:filters": function updateFilters($event) {
        _vm.filters = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "flex justify-between"
        }, [_c('Button', {
          attrs: {
            "type": "button",
            "icon": "pi pi-filter-slash",
            "label": "Clear",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clearFilter();
            }
          }
        }), _c('span', {
          staticClass: "p-input-icon-left"
        }, [_c('i', {
          staticClass: "pi pi-search"
        }), _c('InputText', {
          attrs: {
            "placeholder": "Search Search for a product"
          },
          model: {
            value: _vm.filters['global'].value,
            callback: function callback($$v) {
              _vm.$set(_vm.filters['global'], "value", $$v);
            },
            expression: "filters['global'].value"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function fn() {
        return [_vm._v(" No Product found. ")];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function fn() {
        return [_vm._v(" Loading products data. Please wait. ")];
      },
      proxy: true
    }])
  }, [_c('ColumnGroup', {
    attrs: {
      "type": "header"
    }
  }, [_c('Row', [_c('Column', {
    attrs: {
      "header": "Reference",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Name",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Created At",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Code",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Price",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Quantity",
      "colspan": 3
    }
  }), _c('Column', {
    attrs: {
      "header": "Status",
      "rowspan": 2
    }
  }), _c('Column', {
    attrs: {
      "header": "Actions",
      "rowspan": 2
    }
  })], 1), _c('Row', [_c('Column', {
    attrs: {
      "header": "Total",
      "field": "quantity.total"
    }
  }), _c('Column', {
    attrs: {
      "header": "In Stock",
      "field": "quantity.inStock"
    }
  }), _c('Column', {
    attrs: {
      "header": "Defective",
      "field": "quantity.defective"
    }
  })], 1)], 1), _c('Column', {
    attrs: {
      "field": "ref",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_vm._v(" " + _vm._s(data.ref) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "name",
      "styles": {
        'min-width': '14rem',
        display: 'flex',
        'align-items': 'center'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_c('img', {
          staticClass: "w-12 h-12 rounded-full mr-5",
          attrs: {
            "src": data.image,
            "alt": "product"
          }
        }), _c('span', {
          staticClass: "font-semibold tracking-wide"
        }, [_vm._v(_vm._s(data.name) + " ")])];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "createdAt",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref3) {
        var data = _ref3.data;
        return [_vm._v(" " + _vm._s(new Date(data.createdAt).toLocaleDateString()) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "code",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref4) {
        var data = _ref4.data;
        return [_vm._v(" " + _vm._s(data.code) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "price",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref5) {
        var data = _ref5.data;
        return [_vm._v(" " + _vm._s(data.price) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "quantity.total",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref6) {
        var data = _ref6.data;
        return [_vm._v(" " + _vm._s(data.quantity.total) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "quantity.inStock",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref7) {
        var data = _ref7.data;
        return [_vm._v(" " + _vm._s(data.quantity.inStock) + " ")];
      }
    }])
  }), _c('Column', {
    attrs: {
      "field": "quantity.defective",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref8) {
        var data = _ref8.data;
        return [_vm._v(" " + _vm._s(data.quantity.defective) + " ")];
      }
    }])
  }), _c('Column', {
    staticClass: "flex items-center",
    attrs: {
      "field": "outOfStock",
      "styles": {
        'min-width': '10rem'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref9) {
        var data = _ref9.data;
        return [_c('Tag', {
          attrs: {
            "value": data.quantity.inStock <= 0 ? 'Out of stock' : 'In stock',
            "severity": data.quantity.inStock <= 0 ? 'danger' : 'success'
          }
        })];
      }
    }])
  }), _c('Column', {
    attrs: {
      "headerStyle": {
        'min-width': '4rem',
        'text-align': 'center'
      },
      "bodyStyle": {
        'text-align': 'center',
        overflow: 'visible'
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(_ref10) {
        var data = _ref10.data;
        return [_c('span', {
          staticClass: "flex gap-2"
        }, [_c('router-link', {
          attrs: {
            "to": "edit/".concat(data.id)
          }
        }, [_c('Button', {
          staticClass: "p-button-rounded p-button-text",
          attrs: {
            "icon": "pi pi-pencil"
          }
        })], 1), _c('Button', {
          staticClass: "p-button-text p-button-danger",
          attrs: {
            "icon": "pi pi-times"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowDeleteModal(data.id);
            }
          }
        })], 1)];
      }
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center justify-between mb-8"
  }, [_c('h2', {
    staticClass: "text-2xl font-semibold dark:text-white"
  }, [_vm._v("Product List")])]);

}]

export { render, staticRenderFns }