<script>
import { getProducts } from "../../api/products.api";
import { searchWarehouse } from "../../api/warehouses.api";
import DataTable from "primevue/datatable";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";

import { FilterMatchMode } from "primevue/api";

import DeleteProductModal from "../../components/Products/DeleteProductModal.vue";

export default {
  name: "Products",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    ColumnGroup,
    Row,
    Tag,
    InputText,
    DeleteProductModal,
  },

  data() {
    return {
      products: [],
      sales: [],
      loading: false,
      showDeleteModal: false,
      productId: null,
      filters: null,
      selectedWarehouse: null,
      filteredWarehouses: null,
    };
  },

  methods: {
    clearFilter() {
      this.initFilters();
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    fetchProducts() {
      this.loading = true;
      const params = new URLSearchParams({
        "details.warehouse": "640f45ff09e0330012fbfe6b",
      }); // this should not be hardcoded in the future (warehouse id)
      getProducts(params)
        .then((response) => {
          this.products = response.content.results;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    handleEdit(id) {
      // this.$router.push({ name: "EditProduct", params: { id } });
      console.log("Edit product with id: ", id);
    },

    handleShowDeleteModal(id) {
      this.showDeleteModal = true;
      this.productId = id;
    },

    handleToggleModal(value) {
      this.showDeleteModal = value;
      this.productId = null;
    },
  },

  created() {
    this.initFilters();
  },

  mounted() {
    this.fetchProducts();
  },

  computed: {
    formattedProducts() {
      if (!this.products) return [];
      return this.products.map((product) => {
        return {
          id: product._id,
          ref: product.id,
          name: product.name,
          code: product.code,
          createdAt: product.createdAt,
          price: new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(
            product.details.filter(
              (detail) => detail.warehouse._id === "640f45ff09e0330012fbfe6b"
            )[0].price
          ),
          quantity: product.details.filter(
            (detail) => detail.warehouse._id === "640f45ff09e0330012fbfe6b"
          )[0].quantity,
          image: product.picture,
          description: product.description,
          outOfStock: product.outOfStock,
        };
      });
    },
    warehouseId() {
      return this.$store.getters["wharhouse/warhouseSelected"];
    },
  },
};
</script>

<template>
  <div class="p-8">
    <div
      class="p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <DeleteProductModal
        :show.sync="showDeleteModal"
        :product-id="productId"
        @update:show="handleToggleModal"
        @refetch:products="fetchProducts"
      />

      <div class="flex items-center justify-between mb-8">
        <h2 class="text-2xl font-semibold dark:text-white">Product List</h2>
      </div>
      <div>
        <DataTable
          ref="dt"
          :value="formattedProducts"
          :paginator="true"
          class="p-datatable"
          :rows="10"
          dataKey="_id"
          :loading="loading"
          :rowHover="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 25, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          :globalFilterFields="['name', 'code', 'ref']"
          responsiveLayout="scroll"
          :filters.sync="filters"
        >
          <ColumnGroup type="header">
            <Row>
              <Column header="Reference" :rowspan="2" />
              <Column header="Name" :rowspan="2" />
              <Column header="Created At" :rowspan="2" />
              <Column header="Code" :rowspan="2" />
              <Column header="Price" :rowspan="2" />
              <Column header="Quantity" :colspan="3" />
              <Column header="Status" :rowspan="2" />
              <Column header="Actions" :rowspan="2" />
            </Row>
            <Row>
              <Column header="Total" field="quantity.total" />
              <Column header="In Stock" field="quantity.inStock" />
              <Column header="Defective" field="quantity.defective" />
            </Row>
          </ColumnGroup>
          <template #header>
            <div class="flex justify-between">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Clear"
                outlined
                @click="clearFilter()"
              />
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search Search for a product"
                />
              </span>
            </div>
          </template>
          <template #empty> No Product found. </template>
          <template #loading> Loading products data. Please wait. </template>
          <Column field="ref" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.ref }}
            </template>
          </Column>
          <Column
            field="name"
            :styles="{
              'min-width': '14rem',
              display: 'flex',
              'align-items': 'center',
            }"
          >
            <template #body="{ data }">
              <img
                :src="data.image"
                alt="product"
                class="w-12 h-12 rounded-full mr-5"
              />
              <span class="font-semibold tracking-wide">{{ data.name }} </span>
            </template>
          </Column>
          <Column field="createdAt" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ new Date(data.createdAt).toLocaleDateString() }}
            </template>
          </Column>
          <Column field="code" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.code }}
            </template>
          </Column>
          <Column field="price" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.price }}
            </template>
          </Column>
          <Column field="quantity.total" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.quantity.total }}
            </template>
          </Column>
          <Column field="quantity.inStock" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.quantity.inStock }}
            </template>
          </Column>
          <Column field="quantity.defective" :styles="{ 'min-width': '10rem' }">
            <template #body="{ data }">
              {{ data.quantity.defective }}
            </template>
          </Column>
          <Column
            field="outOfStock"
            :styles="{ 'min-width': '10rem' }"
            class="flex items-center"
          >
            <template #body="{ data }">
              <Tag
                :value="
                  data.quantity.inStock <= 0 ? 'Out of stock' : 'In stock'
                "
                :severity="data.quantity.inStock <= 0 ? 'danger' : 'success'"
              />
            </template>
          </Column>
          <Column
            :headerStyle="{ 'min-width': '4rem', 'text-align': 'center' }"
            :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }"
          >
            <template #body="{ data }">
              <span class="flex gap-2">
                <router-link :to="`edit/${data.id}`">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-text"
                  />
                </router-link>
                <Button
                  icon="pi pi-times"
                  class="p-button-text p-button-danger"
                  @click="handleShowDeleteModal(data.id)"
                />
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
